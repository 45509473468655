<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8 instructions">
                  <h4 class="mb-4">Solicitar cambio de contraseña</h4>
                  <p>Por favor ingrese su correo. A este correo le será enviado un link para que pueda cambiar su contraseña</p>
                </div>
                <vs-alert class="alert-message" title="Globalwork dice:" :active="stateMessage" :color="colorMessage" v-if="stateMessage">
                  {{message}}
                </vs-alert>
                <vs-input type="email" label-placeholder="Email" v-model="user.email" class="w-full mb-6" />

                <div class="flex flex-wrap justify-between my-5">
                  <router-link to="/pages/login">Volver al inicio de sesión</router-link>
                </div>

                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                  <vs-button @click="change" class="w-full sm:w-auto">Cambiar</vs-button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import api from './../../mixins/api.js'
export default {
  mixins: [api],
  data() {
    return {
      user: {
        email: ''
      },
      stateMessage: false,
      colorMessage: '',
      message: ''
    }
  },
  methods: {
    showMessage(stateMessage, message, colorMessage){
      this.stateMessage = stateMessage
      this.message = message
      this.colorMessage = colorMessage      
    },
    change() {
      if (this.user.email != '') {
        this.showMessage(true, 'Creando email para restaurar contraseña ...', 'success')
        this.requestPutWithoutHeaders(process.env.VUE_APP_ROOT_INTRANET + 'api/v1/change_password', { user: this.user}).then(response => {
          if (response.data.status == 200){
            this.showMessage(true, response.data.message, 'success')
            // setTimeout(() => this.stateMessage = false, 20000)            
          } else{
            this.showMessage(true, response.data.message, 'danger')
          }
          
        }, (err) => {
          this.showMessage(true, `No se pudo realizar la solicitud ${err}`, 'danger')
        })
      } else {
        this.showMessage(true, 'por favor ingrese el email', 'danger')
      }
    }
  },
}
</script>
<style>
  .alert-message{
    height: 100% !important;
    margin-bottom: 2rem !important;
  }
  .instructions {
    padding-bottom: 0rem;
  }
</style>
