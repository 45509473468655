var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center",
      },
      [
        _c("vx-card", [
          _c(
            "div",
            {
              staticClass: "full-page-bg-color",
              attrs: { slot: "no-body" },
              slot: "no-body",
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                  },
                  [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: {
                        src: require("@/assets/images/pages/reset-password.png"),
                        alt: "login",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "p-8" },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-card__title mb-8 instructions" },
                          [
                            _c("h4", { staticClass: "mb-4" }, [
                              _vm._v("Solicitar cambio de contraseña"),
                            ]),
                            _c("p", [
                              _vm._v(
                                "Por favor ingrese su correo. A este correo le será enviado un link para que pueda cambiar su contraseña"
                              ),
                            ]),
                          ]
                        ),
                        _vm.stateMessage
                          ? _c(
                              "vs-alert",
                              {
                                staticClass: "alert-message",
                                attrs: {
                                  title: "Globalwork dice:",
                                  active: _vm.stateMessage,
                                  color: _vm.colorMessage,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.message) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("vs-input", {
                          staticClass: "w-full mb-6",
                          attrs: {
                            type: "email",
                            "label-placeholder": "Email",
                          },
                          model: {
                            value: _vm.user.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "email", $$v)
                            },
                            expression: "user.email",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "flex flex-wrap justify-between my-5",
                          },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/pages/login" } },
                              [_vm._v("Volver al inicio de sesión")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-wrap justify-between flex-col-reverse sm:flex-row",
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "w-full sm:w-auto",
                                on: { click: _vm.change },
                              },
                              [_vm._v("Cambiar")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }